

























































import { MetaInfo } from 'vue-meta'
import { Component, Mixins, Watch } from 'vue-property-decorator'
import { DateTime } from 'luxon'

import MasterMyExerciseCard from '@/components/cards/MasterMyExerciseCard.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import MasterGroupMixin from '@/mixins/MasterGroupMixin'
import NotifyMixin from '@/mixins/NotifyMixin'
import DictionaryModule from '@/store/modules/dictionary'
import MasterExercisesModule from '@/store/modules/master/exercises'
import { MasterEducationMasterGroupIdExercisesGetParams } from '@/store/types'
import MasterEducationModule from '@/store/modules/master/education'
import AuthModule from '@/store/modules/auth'
import MasterTutorialsModule from '@/store/modules/master/tutorials'

@Component({
  components: {
    MasterMyExerciseCard,
    Select,
  },
})
export default class ExercisesList extends Mixins(MasterGroupMixin, NotifyMixin) {
  private get filter() {
    return MasterExercisesModule.exerciseContainersFilter
  }

  private set filter(filter: MasterEducationMasterGroupIdExercisesGetParams) {
    MasterExercisesModule.setExerciseContainersFilter(filter)
    this.fetchExercises()
  }

  private get exerciseContainers () {
    return MasterExercisesModule.exerciseContainers
  }

  private get taskTypes () {
    return DictionaryModule.taskTypes
  }

  private get previousMasterGroupID() {
    return MasterEducationModule.previousMasterGroupID
  }

  private get miniTutorialPassed () {
    return AuthModule.self?.miniTutorialPassed
  }

  private get isTourStart() {
    return MasterTutorialsModule.lessonExerciseTutorialActive
  }

  private mounted () {
    const main: HTMLDivElement = document.querySelector('.v-main') as HTMLDivElement
    if (main) {
      main.style.minHeight = 'calc(var(--vh, 1vh) * 100)'
    }

    if (!this.filter.monthId || this.previousMasterGroupID !== this.currentMasterGroupID) {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number, // MY_STEP: 3 Записываем из геттера
      }
      MasterEducationModule.setPreviousMasterGroupID(this.currentMasterGroupID)
    } else {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number, // MY_STEP: 3 Записываем из геттера
      }
    }
    this.startTour()
  }

  private startTour() {
    // Определяем текущий месяц
    const currentMonth = DateTime.now().toFormat('yyyy-MM')
    // Количество купленных месяцев
    const countMonths = this.currentMasterGroup.months.length
    // Определяем первый месяц активной группы
    const firstMonths = this.currentMasterGroup.months.length ? DateTime.fromSQL(this.currentMasterGroup.months[0].name).toFormat('yyyy-MM') : ''
    // Мастер прошёл туториал
    const miniTutorialPassed = !!this.miniTutorialPassed

    if (!this.isTourStart && !miniTutorialPassed && countMonths > 1 && firstMonths && currentMonth > firstMonths) {
      this.$bus.$emit('startLessonExerciseTour', 'exercise')
    }
  }

  private fetchExercises () {
    if (this.currentMasterGroup) {
      MasterExercisesModule.fetchExerciseContainers({
        masterGroupID: this.currentMasterGroup.id,
        params: this.filter,
      })
        .catch(this.notifyError)
    }
  }

  private handleFilter(field: keyof MasterEducationMasterGroupIdExercisesGetParams, value: any) {
    this.filter = {
      ...this.filter,
      [field]: value,
    }

    if (field === 'monthId') MasterEducationModule.setSavedMasterGroupMounthID(value)
  }

  private destroyed() {
    const main: HTMLDivElement = document.querySelector('.v-main') as HTMLDivElement
    if (main) {
      main.style.minHeight = ''
    }
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Домашняя работа',
    }
  }

  @Watch('currentMasterGroupID')
  watchCurrentMasterGroupID () {
    MasterEducationModule.setSavedMasterGroupMounthID(null)
    MasterEducationModule.setPreviousMasterGroupID(this.currentMasterGroupID)
    if (this.currentMasterGroupMonth) {
      this.filter = {
        ...this.filter,
        monthId: this.currentMasterGroupMonthID as number,
      }
    }
  }
}
